import DztImageGalleryComponent from "reactjs-image-gallery";
import './photoGallery.css';

const gallaryProps = {
    hideRotate: true,
    hideZoom: true,
    hideDownload: true
};

export const PhotoGallery = ({ photoSet }) => <>
    <div className="row">
    {
        photoSet.map((x, i) =>
            <div className="col-md-6" key={i}>
                { x.groupName && <h2 className="group-title">{x.groupName}</h2> }
                { x.description && <p className="group-description">{x.description}</p> }
                <DztImageGalleryComponent images={x.photos} {...gallaryProps} />
            </div>)
    }
    </div>
</>;
