export const LOGOS = [
    {
        photos: [
            { url: '', thumbUrl:  '',  title: '' }, 
            { url: '', thumbUrl:  '',  title: '' }, 
            { url: '', thumbUrl:  '',  title: '' }
        ]
    }
];

export const COMMISSIONED_ART = [
    {
        groupName: '"Political Chaos"',
        description: 'Two pieces acrylic set painted on 6\'x6\' canvas',
        photos: [
            { url: 'images/liberty.png', thumbUrl:  'images/liberty.png' }, 
            { url: 'images/justice.png', thumbUrl:  'images/justice.png' }, 
            { url: 'images/political-chaos-canvas.jpg', thumbUrl:  'images/political-chaos-canvas.jpg' }, 
        ]
    },
    {
        groupName: '"Faces"',
        description: 'Acrylic painted on 6\'x4\' canvas',
        photos: [
            { url: 'images/faces.png', thumbUrl:  'images/faces.png' }, 
            { url: 'images/faces-canvas.jpg', thumbUrl:  'images/faces-canvas.jpg' }, 
        ]
    },
    {
        groupName: '"Red Wing"',
        description: 'Acrylic painted on 4\'x4\' canvas',
        photos: [
            { url: 'images/bird-lines.png', thumbUrl:  'images/bird-lines.png' }, 
            { url: 'images/bird-lines-canvas.jpg', thumbUrl:  'images/bird-lines-canvas.jpg' }, 
        ]
    },
    {
        groupName: '"Car"',
        description: 'Acrylic painted on 4\'x4\' canvas',
        photos: [
            { url: 'images/car.png', thumbUrl:  'images/car.png' },
            { url: 'images/car-canvas.jpg', thumbUrl:  'images/car-canvas.jpg' }
        ]
    },
    {
        groupName: '"Hipster"',
        description: 'Charcoal piece drawn on 3\'x2\'',
        photos: [
            { url: 'images/charcoal.jpg', thumbUrl:  'images/charcoal.jpg' }
        ]
    },
    {
        groupName: '"Guns & Floral"',
        description: 'Cast iron sculture',
        photos: [
            { url: 'images/gun-1.jpg', thumbUrl:  'images/gun-1.jpg' }
        ]
    },
    {
        groupName: '"Team Manic"',
        description: 'SAI - Video end card',
        photos: [
            { url: 'images/endcard.png', thumbUrl:  'images/endcard.png' }
        ]
    },
];

export const MARKETING_MATERIAL = [
    {
        groupName: 'Code Launch - Aug 2018',
        description: 'Adobe Illustrator',
        photos: [
            { url: 'images/CodeLaunch-Aug-2018.png', thumbUrl:  'images/CodeLaunch-Aug-2018.png' }
        ]
    },
    {
        groupName: 'The Lost Art of Dying Well',
        description: 'Adobe InDesign',
        photos: [
            { url: 'images/503_Lawrimore_Indd_Project_1-images-1.jpg', thumbUrl:  'images/503_Lawrimore_Indd_Project_1-images-1.jpg' },
            { url: 'images/503_Lawrimore_Indd_Project_1-images-2.jpg', thumbUrl:  'images/503_Lawrimore_Indd_Project_1-images-2.jpg' },
            { url: 'images/503_Lawrimore_Indd_Project_1-images-3.jpg', thumbUrl:  'images/503_Lawrimore_Indd_Project_1-images-3.jpg' },
            { url: 'images/503_Lawrimore_Indd_Project_1-images-4.jpg', thumbUrl:  'images/503_Lawrimore_Indd_Project_1-images-4.jpg' }
        ]
    }
];

export const OTHER_ART = [
    {
        groupName: 'Random Sketches',
        photos: [
            { url: 'images/sketch-1.jpg', thumbUrl:  'images/sketch-1.jpg' },
            { url: 'images/sketch-2.jpg', thumbUrl:  'images/sketch-2.jpg' }
        ]
    }
];