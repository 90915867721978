import { useState } from "react";

export const TextAreaField  = ({label, value, setValue, rows}) => {
    const [ isPopulated, setIsPopulated ] = useState(false);
   
    return (
    <div className="form-group">
        {isPopulated && <label>{label}</label>}
        <textarea
            className="form-control"
            type="text"
            value={value}
            placeholder={label}
            onChange={(e) => {
                setValue && setValue(e.target.value);
                setIsPopulated(e.target.value?.length > 0);
            }}
            rows={rows || 10}
        />
    </div>
    )}