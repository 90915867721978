import { useState } from "react";
import { Card, PhotoGallery, TabbedContent, TextAreaField, TextField } from "./components";
import { ABOUT_ME, COMMISSIONED_ART, MARKETING_MATERIAL, INSTAGRAM_ICON, LINKEDIN_ICON, LOGOS, OTHER_ART, RESUME, WEB_DESIGN } from "./constants";
import { Animated } from "react-animated-css";

export const App = () => {
  const [tabIndex, setTabIndex] = useState(-1);

  const TABS = [
    {
      title: "About Kaitlyn",
      content: <>
        {ABOUT_ME}
      </>
    },
    {
      title: 'Portfolio',
      content: <>
        <Card title="Commissioned Art">
          <PhotoGallery photoSet={COMMISSIONED_ART} />
        </Card>
        <Card title="Marketing Material">
          <PhotoGallery photoSet={MARKETING_MATERIAL} />
        </Card>
      </>
    },
    // {
    //   title: 'Resume',
    //   content: <>
    //     <Card>
    //       <FileGallery fileSet={RESUME} />
    //     </Card>
    //   </>
    // },
    {
      title: 'Pricing',
      content: <>
        <Card title="Custom Art">
          <img id="pricing-image" src="images/bird-lines.png" className="float-end" />
          <p>
            For commissioned-based arcylic work, I typically charge by the square foot. Your painting can be made any size you want!
            Typically, people are looking for more than just an art piece to match their decor. I create something that is unique and meaningful to each client I work with.
          </p>
          <ul className="bullets mt-4 list-group">
            <li>
              <div className="box-2">
                <span className="strong">
                  Custom pricing includes:
                </span>
                <ul>
                  <li>
                    Two brainstorming workshops
                    <ul className="ml-3">
                      <li>Conceptualization and vision</li>
                      <li>Mood boarding</li>
                      <li>Selection of genre, color pallets, composition, etc.</li>
                      <li>Rough pencil sketching</li>
                    </ul>
                  </li>
                  <li>Digital draft of your custom piece</li>
                  <li>Consultation and two rounds of edits after digital draft</li>
                </ul>
              </div>
            </li>
            <li>
              Once painting on canvas begins, the design is final.
            </li>
            <li>
              Canvas can be sold stretched or unstretched. Please note non-standard dimensions may increase price. Base pricing does not include the price of shipping or delivery.
            </li>
            <li>
              To receive your quote, hit the contact button above!
            </li>
          </ul>
          <div className="clear-both"></div>
        </Card>
        <Card title="Design Services">
          <span>Coming Soon...</span>
        </Card>
      </>
    },
    {
      title: 'Contact',
      content: <>
        <Card title="Don't be shy, reach out and let's connect!">
          <TextField label="Name" />
          <TextField label="Email" />
          <TextAreaField label="Message" />
          <button className="btn btn-lg btn-primary col-12">
            Message Kaitlyn
          </button>
        </Card>
      </>
    }
  ];

  return (
    <>
      <div className="container">
        <div className="header">
          <Social />
          <h1>
            <a href="javascript:void(0);" onClick={() => setTabIndex(-1)}>
              Lawrimore<span>Design</span>
            </a>
          </h1>
          <h2 className="lead">KAITLYN LAWRIMORE | ARTIST + DESIGNER</h2>
        </div>
        <Animated animationIn="slideInDown"
          animationOut="slideOutUp"
          animateOnMount={false}
          isVisible={tabIndex === -1}>
          {
            tabIndex === -1 &&
            <div className="landing">
              <img src="images/landing.jpg" alt="Kaitlyn Lawrimore" />
              <div className="landing-overlay">
                <p><span>Art</span> is not about what you <span>see</span>. Its how it makes you <span>feel</span>...</p>
              </div>
            </div>
          }
        </Animated>
        <div className="clearfix"></div>
        <div id="tabs">
          <TabbedContent tabs={TABS} tabIndex={tabIndex} onIndexChange={setTabIndex} />
        </div>
        <div className="footer">
          © Copyright {new Date().getFullYear()} - Lawrimore Design
          <Social />
        </div>
      </div>
    </>
  );
}

export const Social = () => <div className="social">
  <a href="https://www.linkedin.com/in/kaitlynlawrimore/" target="_blank"><img src={LINKEDIN_ICON} alt="LinkedIn" /></a>
  &nbsp; &nbsp;
  <a href="https://www.instagram.com/lawrimore_designs/" target="_blank"><img src={INSTAGRAM_ICON} alt="Instagram" /></a>
</div>;