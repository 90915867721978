import { useState } from "react";

export const TextField = ({label, value, setValue}) => {
    const [ isPopulated, setIsPopulated ] = useState(false);

    return (
        <div className="form-group">
            { isPopulated && <label>{label}</label> }
            <input
                className="form-control"
                type="text"
                value={value}
                placeholder={label}
                onChange={(e) => {
                    setValue && setValue(e.target.value);
                    setIsPopulated(e.target.value?.length > 0);
                }}
            />
        </div>
    );
}