import { Card } from "../components";

export const ABOUT_ME = <>
    <div className="row">
        <div className="col-md-12">
            <img className="card-image" src="images/Kaitlyn_Lawrimore.png" alt="Kaitlyn Lawrimore" />
            <Card>
                <p className="section-lead">
                    Hi, I'm Kaitlyn, a creator of meaningful experiences!
                </p>
                <p>
                    I am an artist and designer in Dallas, Texas.
                    I do freelance acrylic painting as well as creative design for web and print.
                </p>
                <p>
                    My passion for art began with just pencils and a sketchbook when I was a kid. Once in high school,
                    I took my first art class and the rest is history. My passion for design grew from my love of the design
                    process and a deep appreciation for the strategy that goes behind the creation.
                    Art provides me an outlet for creative expression while design allows me to exercise my functional talents.
                    After studying Graphic Design at the University of North Texas, I began doing commission freelance work for both.
                </p>
                <p>
                    I am currently available for hire and always on the lookout for exciting new opportunities!
                </p>
            </Card>
            <div className="clearfix"></div>
        </div>
    </div>
    <div className="m-3 mt-1">
        <div className="row">
            <div className="col-md-4">
                <div className="box mb-4">
                    <Card title="Artistic Capabilities">
                        <div className="row">
                            <div className="col-12">
                                <ul>
                                    <li>
                                        Acrylic
                                    </li>
                                    <li>
                                        Charcoal
                                    </li>
                                    <li>
                                        Pencil
                                    </li>
                                    <li>
                                        Digital
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="col-md-8">
                <div className="box mb-4">
                    <Card title="Design Capabilities">
                        <div className="row">
                            <div className="col-sm-4">
                                <ul>
                                    <li>
                                        User-Centered Design
                                    </li>
                                    <li>
                                        Design Thinking Workshops
                                    </li>
                                    <li>
                                        Usability Studies
                                    </li>
                                    <li>
                                        User Experience
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul>
                                    <li>
                                        Web Design
                                    </li>
                                    <li>
                                        Storyboarding
                                    </li>
                                    <li>
                                        Wireframing
                                    </li>
                                    <li>
                                        Clickable Prototypes
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul>
                                    <li>
                                        Print or Digital Media
                                    </li>
                                    <li>
                                        Logo Design
                                    </li>
                                    <li>
                                        Marketing Collateral
                                    </li>
                                    <li>
                                        Style Guide Development
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</>;