import { useState } from "react";
import { Animated } from "react-animated-css";

export const TabbedContent = ({ tabs, tabIndex = -1, onIndexChange }) => {
    // const [ localTabIndex, setLocalTabIndex ] = useState(tabIndex);

    return (
        <>
            <ul className="nav nav-pills mb-3">
                {
                    tabs.map((x, i) =>
                        <li className="nav-item" key={i}>
                            <button type="button"
                                className={"nav-link" + (tabIndex === i ? " active" : "")}
                                onClick={() => {
                                    onIndexChange(i === tabIndex ? -1 : i);
                                }}>
                                {x.title}
                            </button>
                        </li>)
                }
            </ul>
            {/* <Animated animationIn="slideInUp"
                animateOnMount={false}
                isVisible={tabIndex > -1}> */}
            {
                tabIndex > -1 &&
                <div className="tab-content" id="pills-tabContent">
                    {
                        tabs.map((x, i) =>
                            <div className={"tab-pane fade" + (tabIndex === -1 || tabIndex === i ? " show active" : "")} key={i}>
                                <Animated animationIn="fadeIn"
                                    animationOut="fadeOut"
                                    isVisible={tabIndex === i}>
                                    {x.content}
                                </Animated>
                            </div>
                        )
                    }
                </div>
            }
            {/* </Animated> */}
        </>
    );
};